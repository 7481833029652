import neurunAxios from "./axiosInstance";
import {GpxFile, UpdateGpxPayload} from "../../types/models";

class NeurunApi {
    static async getNeurunTokens(): Promise<{ access_token: string, refresh_token: string }>{
        const result = await neurunAxios.post(`/login`, {
            api_key: process.env.REACT_APP_NEURUN_API_KEY
        })

        return result.data
    }

    static async refreshNeurunTokens(refreshToken: string): Promise<{ access_token: string, refresh_token: string }> {
        const result = await neurunAxios.post(`/refresh`, {
            refresh_token: refreshToken
        })

        return result.data
    }

    static async getNjukoToken(editionId: string): Promise<{ token: string }> {
        const result = await neurunAxios.post(`/njuko/token`, {
            edition_id: editionId
        })

        return result.data
    }

    static async getRace(editionId: string) {
        const result = await neurunAxios.get(`race/${editionId}`)

        return result.data
    }

    static async uploadGpx(payload: FormData): Promise<GpxFile> {
        const result = await neurunAxios.post('gpx/upload', payload, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        })

        return result.data
    }

    static async createRace(payload: Record<string, any>) {
        const result = await neurunAxios.post('race', payload, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        })

        return result.data
    }

    static async updateGpx(payload: UpdateGpxPayload, gpx_guid: string) {
        const result = await neurunAxios.put(`gpx/${gpx_guid}`, payload);

        return result?.data
    }
}

export default NeurunApi
