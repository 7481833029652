import {Box, Tooltip, tooltipClasses, TooltipProps, Typography} from "@mui/material";
import UploadIcon from '../../assets/img/backup-icon.svg'
import React from "react";
import InfoIcon from "../../assets/img/info-icon.svg";
import { styled } from '@mui/material/styles';

interface CustomFileUploadProps {
  id: string;
  title: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  subtext: string;
  acceptType: string;
  withPreview?: boolean;
  error?: string;
  multiple?: boolean;
  tooltipText?: string
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#6271FF',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#6271FF',
    padding: '8px',
    fontSize: '13px',
  },
}));


const CustomFileUpload: React.FC<CustomFileUploadProps> = ({ id, onChange, title, subtext, withPreview = false, acceptType, error, multiple, tooltipText }) => {
  return (
    <Box>
      <Box display='flex' alignItems='center' gap='12px'>
        <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
          {title}
        </Typography>
        {tooltipText ? (
          <CustomTooltip title={tooltipText} arrow>
            <img src={InfoIcon} alt='Info icon' style={styles.tooltipIcon} />
          </CustomTooltip>
        ) : ''}
      </Box>
      <Box sx={{...styles.uploadContainer, border: error ? '1px solid #d32f2f' : '1px solid #ccc' }}>
        <input type="file" id={id} onChange={onChange} accept={acceptType} hidden multiple={multiple}></input>
        <label htmlFor={id}>
          <img src={UploadIcon} alt="Upload" height="24" width="24"/>
          <p style={{ ...styles.uploadText }}>{subtext}</p>
        </label>
      </Box>
      {error ? (
        <span style={{ ...styles.errorMessage }}>{error}</span>
      ) : ''}
    </Box>
  )
}

const styles = {
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '33px',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    }
  },
  uploadText: {
    fontWeight: '500',
    fontSize: '16px',
    margin: '8px 0'
  },
  uploadLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  errorMessage: {
    color: '#d32f2f',
    fontSize: '0.75rem',
    marginLeft: '14px',
  },
  tooltipIcon: {
    width: '16px',
    height: '16px',
    filter: 'invert(33%) sepia(93%) saturate(1301%) hue-rotate(220deg) brightness(88%) contrast(101%)',
  }
}

export default CustomFileUpload;
