import React from 'react';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/system';

const FullScreenContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: '32px 24px'
});

// const Header = styled(Box)({
//     padding: '1rem',
//     backgroundColor: '#1976d2',
//     color: '#fff',
// });
//
// const Footer = styled(Box)({
//     padding: '1rem',
//     backgroundColor: '#1976d2',
//     color: '#fff',
//     marginTop: 'auto',
// });

const Main = styled(Container)({
    flex: 1,
    padding: '1rem',
});

type AppLayoutProps = {
    children: React.ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
    return (
        <FullScreenContainer>
            {/*<CssBaseline />*/}
            {/*<Header>*/}
            {/*    <h1>My Full-Screen App</h1>*/}
            {/*</Header>*/}
            <Main>
                {children}
            </Main>
            {/*<Footer>*/}
            {/*    <p>Footer Content</p>*/}
            {/*</Footer>*/}
        </FullScreenContainer>
    );
};

export default AppLayout;
