import React, { useState } from 'react';
import {TextField, Chip, Box, Typography, InputAdornment} from '@mui/material';
import AddIcon from '../../assets/img/add-icon.svg';

interface ChipInputComponentProps {
  chips: string[];
  onAddChip: (chip: string) => void;
  onDeleteChip: (chip: string) => void;
  title: string;
  required?: boolean;
  placeholder: string;
  error?: string;
}

const ChipInputComponent: React.FC<ChipInputComponentProps> = ({ chips, onAddChip, onDeleteChip, title, required, placeholder, error }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      handleAddValue();
    }
  };

  const handleAddValue = () => {
    onAddChip(inputValue);
    setInputValue('');
  }

  function truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
      return text;
    }

    const startLength = Math.ceil(maxLength / 2);
    const endLength = Math.floor(maxLength / 2);

    return text.slice(0, startLength) + '...' + text.slice(-endLength);
  }

  return (
    <Box>
      <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
        {title}
        {required ? (
          <span style={styles.requiredField}>*</span>
        ) : null}
      </Typography>
      <TextField
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        label=""
        variant="outlined"
        placeholder={placeholder}
        error={!!error}
        fullWidth
        helperText={error}
        sx={styles.inputField}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <img src={AddIcon} alt='Add' style={styles.addButton} onClick={handleAddValue}/>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{marginTop: 2 }}>
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={truncateText(chip, 25)}
            onDelete={() => onDeleteChip(chip)}
            sx={{ margin: '4px' }}
          />
        ))}
      </Box>
    </Box>
  );
}

const styles = {
  inputField: {
    '& .MuiOutlinedInput-root': {
      padding: '4px',
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#ccc',
      },
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '8px',
  },
  addButton: {
    width: '24px',
    height: '24px',
    cursor: 'pointer'
  }
}

export default ChipInputComponent;
