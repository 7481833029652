import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://front-api.njuko.com/',
    headers: {
        'Content-Type': 'application/json',
    },
})

export const setToken = (token: string): void => {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
}


export default axiosInstance


