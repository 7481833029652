import React, {useEffect, useState} from "react";
import {CircularProgress, Box} from "@mui/material";
import NeurunApi from "../../api/neurun";
import {isExpired} from 'react-jwt'


interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthProvider = (props: AuthProviderProps) => {
    const [isAuth, setIsAuth] = useState(false)
    useEffect(() => {
        processToken()
    }, [])

    const processToken = async () => {
        const token = localStorage.getItem('token')
        const refreshToken = localStorage.getItem('token')
        if (!token || isExpired(token)) {
            const { access_token, refresh_token } = await NeurunApi.getNeurunTokens()
            localStorage.setItem('token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
            setIsAuth(true)
            return
        } else if (refreshToken) {
            const { access_token, refresh_token } = await NeurunApi.refreshNeurunTokens(refreshToken)
            localStorage.setItem('token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
            setIsAuth(true)
            return
        }
    }

    if (!isAuth) {
        return (
            <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <CircularProgress />
            </Box>
        )
    }

    return <>{props.children}</>
}

export default AuthProvider
