import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import HomePage from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import AuthProvider from "./сomponents/AuthProvider";
import AppLayout from "./сomponents/AppLayout";

function App() {
  return (
      <AppLayout>
          <AuthProvider>
              <Routes>
                <Route path="/:edition_id" element={<HomePage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
          </AuthProvider>
      </AppLayout>
  );
}

export default App;
