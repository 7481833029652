import React from 'react';
import { Box, TextField, Typography } from "@mui/material";

interface CustomTextFieldProps {
  title: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  required?: boolean;
  numberInput?: boolean;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
                                                           title,
                                                           name,
                                                           onChange,
                                                           value,
                                                           placeholder,
                                                           onBlur,
                                                           error,
                                                           required = false,
                                                           numberInput = false,
                                                         }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (numberInput) {
      const isValid = /^-?\d*\.?\d*$/.test(inputValue);

      if (isValid) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  return (
    <Box width='100%'>
      <Typography fontWeight='600' fontSize='16px' fontFamily='Montserrat' py={0.5}>
        {title}
        {required ? (
          <span style={styles.requiredField}>*</span>
        ) : null}
      </Typography>
      <TextField
        onChange={handleInputChange}
        onBlur={onBlur}
        name={name}
        value={value}
        sx={styles.inputField}
        variant="outlined"
        fullWidth
        error={!!error}
        placeholder={placeholder}
        helperText={error}
        type={numberInput ? 'text' : 'text'}
        inputProps={numberInput ? { inputMode: 'decimal' } : {}}
      />
    </Box>
  );
}

const styles = {
  inputField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '& fieldset': {
        borderColor: '#ccc',
      },
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '8px'
  }
}

export default CustomTextField;
