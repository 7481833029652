import {Paper, Typography} from "@mui/material";

const NotFound = () => {
    return (
        <Paper>
            <Typography variant={'h1'}>Page not found</Typography>
        </Paper>
    )
}

export default NotFound
